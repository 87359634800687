<template>
  <div>
    <v-card v-if="!hasSEAPermissions">
      <v-card-text>
        <v-row>
          <v-col>
            <h3 style="color: red;">IN DEVELOPMENT - do not use.</h3>
            <p>Use this screen to enter new provider discounts or to edit existing ones.<br />
            Please note that your changes will need to be approved by a member of SEA before they take effect.</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col>
              Discounts
            </v-col>
            <v-col>
              <v-btn class="float-right mr-5" color="primary" @click="showCreateItem()">Add</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete v-model="selectedVertical" :items="activeVerticals" item-text="name" item-value="id" label="Vertical" 
                clearable prepend-icon="construction">
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="selectedCountry" :items="activeCountries" item-text="name" item-value="id" label="Country" 
                clearable prepend-icon="public">
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="selectedLanguage" :items="activeLanguages" item-text="name" item-value="id" label="Language" 
                clearable prepend-icon="language">
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="selectedApprovalStatus" :items="discountApprovalStati" label="Approval status" 
                clearable prepend-icon="grading">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="mt-2" color="primary" v-on:click="show">Show</v-btn>
            </v-col>
            <v-col>
            </v-col>
            <v-col>
            </v-col>
            <v-col>
            </v-col>
          </v-row>
        </v-container>

      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': datatables_rowsPerPageItems,
        }"
        :server-items-length="totalItems"
        class="elevation-1"
        :item-class="getApprovalStatusClass"
      >
        <template v-slot:loading>
          Loading items..
        </template>

        <template v-slot:[`item.provider`]="{ item }">
          <span v-if="item.provider_is_dominant" style="font-weight: bold;">{{item.provider}}</span>
          <span v-else>{{item.provider}}</span>
        </template>

        <template v-slot:[`item.approval_status`]="{ item }">
          <v-icon>{{ getApprovalStatusIcon(item.approval_status) }}</v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="showEditItem(item.id, item.verticals_fk, item.countries_fk, item.languages_fk, item.providers_fk, item.campaign_names_csv, item.duration_type, item.valid_from, 
            item.valid_until_str, item.value, item.suggested_value, item.entry_by, item.last_modified_by)">
            <v-icon style="color: blue;" small>edit</v-icon>
          </v-btn>
          <v-btn v-if="hasSEAPermissions" :disabled="item.approval_status === 'Approved'" icon @click="approve(item.id)">
            <v-icon style="color: green;" small>done</v-icon>
          </v-btn>
          <v-btn v-if="hasSEAPermissions" :disabled="item.approval_status === 'Declined'" icon @click="decline(item.id)">
            <v-icon style="color: orange;" small>close</v-icon>
          </v-btn>
          <v-btn v-if="hasSEAPermissions" icon @click="softDeleteItem(item.id)">
            <v-icon style="color: red;" small>delete</v-icon>
          </v-btn>
          <v-btn v-if="hasSEAPermissions" class="ml-2" icon @click="setDominantProvider(item.verticals_fk, item.countries_fk, item.languages_fk, item.providers_fk)">
            Dom
          </v-btn>
        </template>

        <template v-slot:[`footer.page-text`]="props">
          {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
        </template>

      </v-data-table>

      <v-dialog v-model="showModal" persistent max-width="600px">
        <v-card>

          <v-card-title>
            <span class="headline" v-if="modalMode === 'Create'">Add discount</span>
            <span class="headline" v-if="modalMode === 'Edit'">Edit discount # {{formID}}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-form ref="form">

                    <v-autocomplete v-model="formVertical" :items="activeVerticals" item-value="id" item-text="name" label="Vertical" :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                      required :rules="[v => !!v || 'Vertical is required']"></v-autocomplete>

                    <v-autocomplete v-model="formCountry" :items="activeCountries" item-value="id" item-text="name" label="Country" :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                      required :rules="[v => !!v || 'Country is required']" @change="formLanguage = null"></v-autocomplete>

                    <v-autocomplete v-model="formLanguage" :items="activeLanguages" item-value="id" item-text="name" label="Language" clearable 
                      :disabled="(modalMode === 'Edit' && !hasSEAPermissions) || !getFormCountryUsesLanguage()"
                    ></v-autocomplete>

                    <v-autocomplete v-model="formProvider" :items="activeProviders" item-value="id" item-text="name" label="Provider" :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                      required :rules="[v => !!v || 'Provider is required']"
                    ></v-autocomplete>

                    <v-text-field v-model="formCampaignNamesCSV" label="Campaign name(s) - comma separated" prepend-icon="text_snippet" clearable :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                    ></v-text-field>

                    <v-autocomplete v-model="formDurationType" :items="durationTypes" label="Type" required :rules="[v => !!v || 'Type is required']" :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                    ></v-autocomplete>

                    <v-menu ref="validFromMenu" v-model="showValidFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" :disabled="modalMode === 'Edit' && !hasSEAPermissions">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :value="formattedValidFrom" label="Valid from" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" required :rules="[v => !!v || 'Valid from is required']" :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="formValidFrom" no-title scrollable @change="$refs.validFromMenu.save(formValidFrom)"
                      ></v-date-picker>
                    </v-menu>

                    <v-menu ref="validUntilMenu" v-model="showValidUntilMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" :disabled="modalMode === 'Edit' && !hasSEAPermissions">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :value="formattedValidUntil" label="Valid until" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" clearable @click:clear="formValidUntil = null" :disabled="modalMode === 'Edit' && !hasSEAPermissions"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="formValidUntil" no-title scrollable @change="$refs.validUntilMenu.save(formValidUntil)"
                      ></v-date-picker>
                    </v-menu>

                    <v-text-field v-model="formValue" label="Value" prepend-icon="text_snippet" 
                      :required="hasSEAPermissions" :rules="hasSEAPermissions ? [v => !!v || 'Value is required'] : []" :disabled="!hasSEAPermissions">
                    </v-text-field>

                    <v-text-field v-model="formSuggestedValue" label="Suggested value" prepend-icon="text_snippet" 
                      :required="!hasSEAPermissions" :rules="!hasSEAPermissions ? [v => !!v || 'Suggested value is required'] : []" :disabled="hasSEAPermissions">
                    </v-text-field>

                    <v-text-field v-if="modalMode === 'Edit'" :value="'Created by ' + formEntryBy + ', last changed by ' + formLastModifiedBy" disabled flat dense></v-text-field>

                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
            <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

    </v-card>
  </div>
</template>

<script>
import auth from '@/auth'
import dataTablesMixin from '@/mixins/datatables'
import formsMixin from '@/mixins/forms'

export default {
  name: 'DiscountsTable',

  data () {
    return {
      headers: [
        { text: 'Vertical', value: 'vertical' },
        { text: 'Geo', value: 'co_lang' },
        { text: 'Provider', value: 'provider' },
        { text: 'Campaign(s)', value: 'campaign_names_csv' },
        { text: 'Type', value: 'duration_type' },
        { text: 'Valid from', value: 'valid_from_display' },
        { text: 'Valid until', value: 'valid_until_display' },
        { text: 'Approved?', value: 'approval_status' },
        { text: 'Value', value: 'value' },
        { text: 'Sugg. value', value: 'suggested_value' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      item: {
        verticals_fk: null,
        countries_fk: null,
        languages_fk: null,
        providers_fk: null,
        campaign_names_csv: null,
        duration_type: null,
        valid_from: null,
        valid_until: null,
        has_valid_until: null,
        value: null,
        suggested_value: null,
      },
      valid: false,
      showModal: false,
      modalMode: null, // Create or Edit
      formID: null,
      formVertical: null,
      formCountry: null,
      formLanguage: null,
      formProvider: null,
      formCampaignNamesCSV: null,
      formDurationType: null,
      formValidFrom: null,
      showValidFromMenu: false,
      formValidUntil: null,
      showValidUntilMenu: false,
      formValue: null,
      formSuggestedValue: null,
      formEntryBy: null,
      formLastModifiedBy: null,
      durationTypes: ['Regular', 'Temporary'],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'discounts_options',
      lsFilters: 'discounts_filters',
      endpoint: '/a/core/discounts',
      selectedVertical: null,
      selectedCountry: null,
      selectedLanguage: null,
      selectedApprovalStatus: null,
    }
  },

  mixins: [dataTablesMixin, formsMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries.filter(val => val.id !== 0)
    },
    activeLanguages () {
      return this.$store.state.common.activeLanguages.filter(val => val.id !== 0)
    },
    activeProviders () {
      return this.$store.state.core.activeProviders.filter(val => val.id !== 0)
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals.filter(val => val.id !== 0)
    },
    discountApprovalStati () {
      return this.$store.state.core.discountApprovalStati
    },

    formattedValidFrom () {
      if (!this.formValidFrom) { return '' }

      const dateObj = this.$dateTime.fromSQL(this.formValidFrom)
      return dateObj.toFormat('dd MMM yyyy')
    },
    formattedValidUntil () {
      if (!this.formValidUntil) { return '' }

      const dateObj = this.$dateTime.fromSQL(this.formValidUntil)
      return dateObj.toFormat('dd MMM yyyy')
    },
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    approve: function (id) {
      var myURL = this.endpoint + '/' + id + '/approve'
      this.$http.put(myURL, null).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },
    
    closeModal: function () {
      this.formID = null
      this.formVertical = null
      this.formCountry = null
      this.formLanguage = null
      this.formProvider = null
      this.formCampaignNamesCSV = null
      this.formDurationType = null
      this.formValidFrom = null
      this.formValidUntil = null
      this.formValue = null
      this.formSuggestedValue = null
      this.formEntryBy = null
      this.formLastModifiedBy = null

      this.showModal = false
    },

    createItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.fillItemFromSelection()

      var body = JSON.stringify(this.item)
      this.$http.post(this.endpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.endpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    decline: function (id) {
      var myURL = this.endpoint + '/' + id + '/decline'
      this.$http.put(myURL, null).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    getApprovalStatusClass (item) {
      switch (item.approval_status) {
        case 'Declined':
          return 'declined'
        case 'Under review':
          return 'under-review'
        default:
          return ''
      }
    },

    getApprovalStatusIcon (approvalStatus) {
      switch (approvalStatus) {
        case 'Approved':
          return 'done'
        case 'Declined':
          return 'close'
        case 'Under review':
          return 'grading'
        default:
          return 'help'
      }
    },

    getFormCountryUsesLanguage: function () {
      if (!this.formCountry) {
        return false
      }

      var countriesUsingLanguage = ['Belgium', 'Canada', 'Switzerland']

      // get country name from selected formCountry id
      var coName = this.activeCountries.filter(el => el.id == this.formCountry).map(val => val.name)[0]

      if (countriesUsingLanguage.includes(coName)) {
        return true
      }
      return false
    },

    softDeleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }

      var myURL = this.endpoint + '/' + id + '/soft'

      this.$http.delete(myURL).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    editItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate() || !this.formID) {
        return
      }

      this.fillItemFromSelection()
      //console.log(this.item)

      var body = JSON.stringify(this.item)
      var myURL = this.endpoint + '/' + this.formID
      this.$http.put(myURL, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    fetchData: function () {
      this.loading = true
      var myURL = this.endpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id', 'provider_is_dominant', 'verticals_fk', 'countries_fk', 'languages_fk', 'providers_fk', 'valid_from', 'valid_until_str',
        'entry_by', 'last_modified_by'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter vertical
      if (this.selectedVertical) {
        myURL += '&verticals_fk=' + this.selectedVertical
      }

      // filter country
      if (this.selectedCountry) {
        myURL += '&countries_fk=' + this.selectedCountry
      }

      // filter language
      if (this.selectedLanguage) {
        myURL += '&languages_fk=' + this.selectedLanguage
      }

      // filter approval status
      if (this.selectedApprovalStatus) {
        myURL += '&approval_status=' + this.selectedApprovalStatus
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    fillItemFromSelection: function () {
      this.item['verticals_fk'] = this.formVertical
      this.item['countries_fk'] = this.formCountry
      this.item['languages_fk'] = this.formLanguage
      this.item['providers_fk'] = this.formProvider
      this.item['campaign_names_csv'] = this.formCampaignNamesCSV
      this.item['duration_type'] = this.formDurationType
      this.item['valid_from'] = this.formValidFrom
      this.item['valid_until'] = this.formValidUntil
      this.item['value'] = this.formValue
      this.item['suggested_value'] = this.formSuggestedValue

      if (this.formValidUntil) {
        this.item['has_valid_until'] = true
      } else {
        this.item['has_valid_until'] = false
      }
    },

    save: function () {
      if (this.modalMode === 'Create') {
        this.createItem()
      } else {
        this.editItem()
      }
    },

    setDominantProvider: function (verticals_fk, countries_fk, languages_fk, providers_fk) {

      var domItem = {
        verticals_fk: verticals_fk,
        countries_fk: countries_fk,
        languages_fk: languages_fk,
        providers_fk: providers_fk,
      }

      var body = JSON.stringify(domItem)
      var myURL = this.endpoint + '/set-dominant-provider'
      this.$http.put(myURL, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    show: function () {
     let filterObj = {
        'selectedVertical': this.selectedVertical,
        'selectedCountry': this.selectedCountry,
        'selectedLanguage': this.selectedLanguage,
        'selectedApprovalStatus': this.selectedApprovalStatus,
      }

      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },

    showEditItem: function (id, verticals_fk, countries_fk, languages_fk, providers_fk, campaign_names_csv, duration_type, valid_from, valid_until_str, value, suggested_value, entry_by, last_modified_by) {
      this.formID = id
      this.formVertical = verticals_fk
      this.formCountry = countries_fk
      this.formLanguage = languages_fk
      this.formProvider = providers_fk
      this.formCampaignNamesCSV = campaign_names_csv
      this.formDurationType = duration_type
      this.formValidFrom = valid_from
      this.formValidUntil = valid_until_str
      this.formValue = value
      this.formSuggestedValue = suggested_value

      this.formEntryBy = entry_by
      this.formLastModifiedBy = last_modified_by

      this.modalMode = 'Edit'
      this.showModal = true
    },
    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      
      this.modalMode = 'Create' 
      this.showModal = true
    }
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedVertical']) { this.selectedVertical = filters['selectedVertical'] }
      if (filters['selectedCountry']) { this.selectedCountry = filters['selectedCountry'] }
      if (filters['selectedLanguage']) { this.selectedLanguage = filters['selectedLanguage'] }
      if (filters['selectedApprovalStatus']) { this.selectedApprovalStatus = filters['selectedApprovalStatus'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
